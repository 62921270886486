:root {
	/* fonts */
	--font-family-serif: "Recoleta Alt", serif;
	--font-family-sans: "General Sans Variable", sans-serif;

	--font-feature-settings-serif: "ordn" on, "salt" on, "ss01" on, "liga" off;
	--font-feature-settings-sans: "ordn" on, "salt" on, "liga" off;

	--font-xxl: normal 400 64px/1.1 var(--font-family-serif);
	--font-xl: normal 400 48px/1.1 var(--font-family-serif);
	--font-lg: normal 500 32px/1.2 var(--font-family-serif);
	--font-md: normal 500 24px/1.2 var(--font-family-serif);
	--font-sm: normal 500 18px/1.36 var(--font-family-serif);
	--font-xs: normal 400 14px/1.35 var(--font-family-serif);
	--font-xxs: normal 500 10px/1.4 var(--font-family-sans);
	--font-price: normal 500 14px/1.35 var(--font-family-sans);

	--font-body-lg: normal 500 20px/1.5 var(--font-family-sans);
	--font-body: normal 500 1rem/1.5 var(--font-family-sans);
	--font-body-sm: normal 500 12px/1.4 var(--font-family-sans);
	--font-body-bold: normal 600 16px/25.6px var(--font-family-sans);
	--font-button: normal 600 0.75rem/1.333 var(--font-family-sans);

	--font-link: normal 600 16px/24px var(--font-family-sans);
	--font-link-sm: normal 600 12px/16.8px var(--font-family-sans);
}

@font-face {
	font-family: "Recoleta Alt";
	src: url("/fonts/Recoleta Alt Regular.otf") format("opentype");
	font-weight: 400;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: "Recoleta Alt";
	src: url("/fonts/Recoleta Alt Medium.otf") format("opentype");
	font-weight: 500;
	font-display: swap;
	font-style: normal;
}

@font-face {
	font-family: "Recoleta Alt";
	src: url("/fonts/Recoleta Alt Bold.otf") format("opentype");
	font-weight: 700;
	font-display: swap;
	font-style: normal;
}

// This is a variable font! Awesome!
@font-face {
	font-family: "General Sans Variable";
	src: url("/fonts/GeneralSans-Variable.ttf") format("truetype"),
		url("/fonts/GeneralSans-Variable.woff2") format("woff2-variations");
	font-weight: 125 950;
	font-stretch: 75% 125%;
	font-style: normal;
	font-display: swap;
}
