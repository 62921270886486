/*
  Josh's Custom CSS Reset
  https://www.joshwcomeau.com/css/custom-css-reset/
*/
*,
*::before,
*::after {
	box-sizing: border-box;
}

* {
	margin: 0;
}

/*
	Note: this breaks framer-motion's useViewportScroll() hook
	And probably also other viewport based heights.
 */
html,
body,
#__next {
	min-height: 100%;
}

body {
	line-height: 1.5;
	-webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
	display: block;
	max-width: 100%;
}

input,
button,
textarea,
select {
	font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	overflow-wrap: break-word;
}

#root,
#__next {
	isolation: isolate;
}
