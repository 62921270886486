:root {
	/* colors */
	--wsw-100: hsl(0, 8%, 18%);
	--wsw-75: hsl(0, 6%, 31%);
	--wsw-50: hsl(0, 2%, 57%);
	--wsw-25: hsl(0, 8%, 92%);
	--wsw-10: hsl(0, 7%, 97%);
	--wsw-5: hsl(0, 6.7%, 97.1%);
	--wsw-0: hsl(0, 0%, 100%);
	--wsw-transparent: hsla(0, 8%, 18%, 0.75);
	--wsw-blue: hsl(183.5, 66.5%, 69.6%);
	--wsw-yellow: hsl(47, 98.9%, 65.3%);
	--wsw-red: hsla(356.6, 85.4%, 59.6%);
	--wsw-green: hsl(138, 100%, 20%);
	--wsw-purple: hsl(266.7, 52.9%, 33.3%);
	--wsw-magento: hsl(312, 50%, 39.2%);
	--wsw-pink: hsl(342.4, 85%, 60.8%);

	// backwards compatibility aliases.
	--blue: var(--wsw-blue);
	--yellow: var(--wsw-yellow);
}
