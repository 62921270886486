@import "./partials/reset.scss";
@import "./partials/colors.scss";
@import "./partials/fonts.scss";
@import "./partials/breakpoints.scss";
@import "./partials/shadows.scss";
@import "./partials/borders.scss";

@import "./mautic.scss";

html,
body {
	font-family: var(--font-family-sans);
	background-color: var(--wsw-10);

	/* Disable the elastic scroll bounce */
	overscroll-behavior-y: none;
}

body.no-scroll {
	overflow: hidden;
}

nav {
	transition: all 0.3s ease;

	&.scroll-up {
		background-color: var(--wsw-10);
		height: 68px;
		border-bottom: 1px solid var(--wsw-100);
	}

	&.hide-nav {
		top: -120px;
	}
}

#__next {
	background-color: inherit;
}

a {
	color: inherit;
	text-decoration: none;
}

:root {
	--container-size: 1124px;
}

.hidden {
	opacity: 0;
}

.none {
	display: none;
}

*:focus-visible {
	outline-offset: 4px;
	outline-color: var(--wsw-pink);
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	-webkit-text-fill-color: var(--wsw-100);
	box-shadow: 0 0 0px 1000px var(--yellow) inset;
	-webkit-box-shadow: 0 0 0px 1000px var(--yellow) inset;
}
