@use "styles/utils/media.module";

:root {
	/* breakpoints */
	--breakpoint-sm: #{map-get(media.$breakpoints, sm)};
	--breakpoint-md: #{map-get(media.$breakpoints, md)};
	--breakpoint-lg: #{map-get(media.$breakpoints, lg)};
	--breakpoint-xl: #{map-get(media.$breakpoints, xl)};
	--breakpoint-xxl: #{map-get(media.$breakpoints, xxl)};
}
